export const useGeneratorStore = defineStore('generator', {
    state: () => ({
        product: localStorage.getItem('selectedProduct') || {},
        buyer: localStorage.getItem('selectedBuyer') || {},
        intent: localStorage.getItem('intent') || ''
    }),
    actions: {
        setBuyer(state) {
            this.buyer = state;
            localStorage.setItem('selectedBuyer', state);
        },
        setProduct(state) {
            this.product = state;
            localStorage.setItem('selectedProduct', state);
        },
        clearBuyer(){
            this.buyer = '';
            localStorage.removeItem('selectedBuyer');
        },
        clearProduct(){
            this.product = '';
            localStorage.removeItem('selectedProduct');
        },
        setIntent(intent){
            this.intent = intent;
            localStorage.setItem('intent', intent);
        },
        clearIntent(){
            this.intent = '';
            localStorage.removeItem('intent');
        },
        clear(){
            this.clearProduct();
            this.clearBuyer();
            this.clearIntent();
        }
    }
})
